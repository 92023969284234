
import React from 'react';
import '../HomePage.css';

const photos = [
  { id: 1, url: 'https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg' },
  { id: 2, url: 'https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg' },
  { id: 3, url: 'https://images.pexels.com/photos/259588/pexels-photo-259588.jpeg' },
  { id: 4, url: 'https://images.pexels.com/photos/1370704/pexels-photo-1370704.jpeg'}
];

const HomePage = () => {
  return (
    <>
      <h1 className='center'>Real Estate Photography</h1>
    <div className="home-container">
      {photos.map(photo => (
        <a href={photo.url} key={photo.id} target="_blank" rel="noopener noreferrer">
          <div className="photo-card">
            <img src={photo.url} alt={`Real Estate ${photo.id}`} />
          </div>
        </a>
      ))}
    </div>
    </>
  );
};

export default HomePage;

