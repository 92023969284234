import React from 'react';
import '../../src/About.css';

const About = () => {
  return (
    <div className='about' style={{ padding: '20px' }}>
      <h2>About Us</h2>
      <p>
        Focus Click Click! is a real estate photography company in Mineral Wells, Texas, serving Palo Pinto & the neighboring counties.<br/> We're <em>focused</em> on making life easier for you. 
      </p>
      <p>
        Whether you're looking for interior, exterior, or drone photography, we have the skills
        and tools to meet your needs.<br/> We believe in the power of visuals to tell a story.<br/> Keep things simple & <strong className='call'>BOOK TODAY</strong>! 
      </p>
      <p>
        Email Us at <span className='aqua'>hello@focusclickclick.com</span>.
      </p>
      
      
    </div>
  );
};

export default About;
