import React from 'react';
import '../../src/Contact.css';

const Contact = () => {
  const packages = [
    { title: 'Standard Package', price: '$250', description: 'Interior and exterior photos, up to 20 shots.', class: 'package' },
    { title: 'Aerial Package', price: '$300', description: 'Standard Package + 5 aerial photos.', class: 'package'},
    { title: 'Aerial Photos', price:'$200', description: '5 aerial photos.', class:'single'},
    { title: 'Floor Plan', price: '$35', description: 'Deliver a clear overview. Adding a floorplan boosts the appeal of your listing, giving you a competitive edge in the market.', class:'single'},
    { title: 'Drone Video', price: '$150', description: 'Add 30 second of drone footage.', class:'single'},
    { title: 'Custom Package', price: 'Contact for pricing', description: 'Tailored to your needs.', class:'package' }
  ];

  // const disc = [
  //   { title: '5% Discount', description: 'Save 5% when you book 5 listings.'},
  //   { title: '10% Discount', description: 'Save 10% when you book 10 listings.'},
  //   { title: '20% Discount', description: 'Save 20% when you book 20 or more listings.'}

  // ];

  return (
    <>
    <p className="contact">
      Email 📧 at hello@focusclickclick.com
    </p>
      
    {/* <div className="disc-container">
      {disc.map((d, index) => (
        <div key={index} className='disc-card'>
          <h3>{d.title}</h3>
          <p>{d.description}</p>
        </div>
      ))}

    </div> */}
    <div className="pricing-container">
      
      {packages.map((pkg, index) => (
        <div key={index} className={`pricing-card ${pkg.class}`}>
          <h3>{pkg.title}</h3>
          <p>{pkg.price}</p>
          <p>{pkg.description}</p>
        </div>
      ))}
    </div>
      
    </>
  );
};

export default Contact;
